import * as React from "react"

import Layout from "../components/layout.js"

const NotFoundPage = () => {
  const title = `404: ページが見つかりません`
  return (
    <Layout title={title}>
      <h1 className="text-2xl mb-10">{title}</h1>
      <p>お探しのページが見つかりませんでした。</p>
    </Layout>
  )
}

export default NotFoundPage
